var Slider = function(el, obj) {
  this.$el = $(el).first();
  this.next = this.$el.find(el +'__next');
  this.prev = this.$el.find(el +'__prev');
  this.container = $(el).find('.slider__containter');
  this.length = $(el).find('.slider__containter').children().length;
  this.itemDisplayed = obj.length || 4;
  this.item = $(el).find('.slider-item');
  this.navigation = (obj.nav) ? navigation.call(this, el) : undefined;
  var newSlider = slider();

  function slider() {
    var pos = 0;
    return function(direction, manual) {
      manual= manual || null;
      if (this.navigation) this.navigation.update(pos);
      pos = (typeof(manual) === 'boolean' && manual)
                  ? direction
                  : position.call(this, pos + direction);
      if (this.navigation) this.navigation.update(pos);
      this.container.animate({ right: pos * this.item.width() });
    };
  }

  function navigation(el) {
    var nav = $('<div />', {'class': (el.slice(1) + '__navigation') + ' slider__navigation' });
    this.$el.append(nav);
    for (var i = 0; i < this.length; i++) {
      var button = '<span data-id="' + i + '" class="bullet ' + ((i === 0) ? 'active' : '') + '"></span>';
      nav.append(button);
    }

    return {
      el: nav,
      update: function (index) {
        $(nav.children()[index]).toggleClass('active');}
    };
  }

  // Adding events for changin slides
  this.next.on('click', newSlider.bind(this, 1));
  this.prev.on('click', newSlider.bind(this, -1));

  //Add swiping to mobile
  var hammertime = new Hammer(this.container[0], {});
  hammertime.on('swipeleft', newSlider.bind(this, 1));
  hammertime.on('swiperight', newSlider.bind(this, -1));

  //Navigation element
  if (this.navigation) {
    this.navigation.el.on('click', 'span', function(e) {
      var index = e.target.dataset.id;
      newSlider.call(this, parseInt(index), true);
    }.bind(this));
  }

  // Checking positon of slider
  function position(num) {
    if (num < 0) {
      return (this.length - this.itemDisplayed);
    }

    if (num > (this.length - this.itemDisplayed)) {
      return 0;
    }

    return num;
  }
};
